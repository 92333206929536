var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "feather-icons"
    }
  }, [_c('div', {
    staticClass: "icon-search-wrapper my-3 mx-auto"
  }, [_c('b-form-group', [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "SearchIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "icons-search",
      "placeholder": "Search Icons..."
    },
    model: {
      value: _vm.seachQuery,
      callback: function callback($$v) {
        _vm.seachQuery = $$v;
      },
      expression: "seachQuery"
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "d-flex flex-wrap",
    attrs: {
      "id": "icons-container"
    }
  }, _vm._l(_vm.filterIcons, function (icon) {
    return _c('b-card', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover.top",
        value: icon,
        expression: "icon",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      key: icon,
      staticClass: "icon-card cursor-pointer text-center mb-2 mx-50",
      on: {
        "click": function click($event) {
          return _vm.copyIconName(icon);
        }
      }
    }, [_c('div', {
      staticClass: "icon-wrapper"
    }, [_c('feather-icon', {
      attrs: {
        "icon": icon,
        "size": "24"
      }
    })], 1), _c('b-card-text', {
      staticClass: "icon-name text-truncate mb-0 mt-1"
    }, [_vm._v(" " + _vm._s(icon) + " ")])], 1);
  }), 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }